function RightArrow({ arrowSize }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={arrowSize}
      height={arrowSize}
      viewBox="0 0 67 68"
      fill="none"
    >
      <circle cx="33.3828" cy="33.8926" r="33.3828" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.477 34.6566C20.0042 34.2571 20.0042 33.5284 20.477 33.1289L28.363 26.4657C28.5739 26.2875 28.8894 26.314 29.0676 26.525C29.2458 26.7359 29.2193 27.0514 29.0084 27.2296L21.6958 33.4082H46.1445C46.4207 33.4082 46.6445 33.6321 46.6445 33.9082C46.6445 34.1844 46.4207 34.4082 46.1445 34.4082H21.7325L29.0084 40.5558C29.2193 40.7341 29.2458 41.0495 29.0676 41.2605C28.8894 41.4714 28.5739 41.4979 28.363 41.3197L20.477 34.6566Z"
        fill="#112E3D"
      />
    </svg>
  );
}

export default RightArrow;
