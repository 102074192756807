function LeftArrow({ arrowSize }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={arrowSize}
      height={arrowSize}
      viewBox="0 0 67 68"
      fill="none"
    >
      <circle cx="33.6172" cy="33.8926" r="33.3828" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.523 34.6566C46.9958 34.2571 46.9958 33.5284 46.523 33.1289L38.637 26.4657C38.4261 26.2875 38.1106 26.314 37.9324 26.525C37.7542 26.7359 37.7807 27.0514 37.9916 27.2296L45.3042 33.4082H20.8555C20.5793 33.4082 20.3555 33.6321 20.3555 33.9082C20.3555 34.1844 20.5793 34.4082 20.8555 34.4082H45.2675L37.9916 40.5558C37.7807 40.7341 37.7542 41.0495 37.9324 41.2605C38.1106 41.4714 38.4261 41.4979 38.637 41.3197L46.523 34.6566Z"
        fill="#112E3D"
      />
    </svg>
  );
}

export default LeftArrow;
