// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    background-color: var(--osc-green-07);
    text-align: left;
    color: var(--white);
}

.footer {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    padding: 7.5em 5.5em;
    justify-content: center;
}

.footer-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.practice-details .footer-column{
    width: 282px;
}

.footer-links {
    display: flex;
}

.footer-column {
    display: flex;
    flex-direction: column;
    width: 198px;
    gap: var(--3sm);
    padding-top: var(--2sm);
}

/* a {
    text-decoration: none;
    color: var(--white);
    font-size: 16px;
    line-height: 24px;
} */

p.footer-title {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    color: var(--gray-03);
}

.footer-image .nav-image-container{
    width: 179px;
    height: auto;
}

.fine-print {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

p.cc {
    color: var(--gray-03);
}

a.pp {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,oBAAoB;IACpB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,uBAAuB;AAC3B;;AAEA;;;;;GAKG;;AAEH;IACI,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".footer-container {\n    background-color: var(--osc-green-07);\n    text-align: left;\n    color: var(--white);\n}\n\n.footer {\n    display: flex;\n    flex-direction: column;\n    max-width: 1440px;\n    padding: 7.5em 5.5em;\n    justify-content: center;\n}\n\n.footer-content {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n}\n\n.practice-details .footer-column{\n    width: 282px;\n}\n\n.footer-links {\n    display: flex;\n}\n\n.footer-column {\n    display: flex;\n    flex-direction: column;\n    width: 198px;\n    gap: var(--3sm);\n    padding-top: var(--2sm);\n}\n\n/* a {\n    text-decoration: none;\n    color: var(--white);\n    font-size: 16px;\n    line-height: 24px;\n} */\n\np.footer-title {\n    font-size: 14px;\n    line-height: 20px;\n    letter-spacing: 1.4px;\n    color: var(--gray-03);\n}\n\n.footer-image .nav-image-container{\n    width: 179px;\n    height: auto;\n}\n\n.fine-print {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 50px;\n}\n\np.cc {\n    color: var(--gray-03);\n}\n\na.pp {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
